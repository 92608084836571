import { CssBaseline } from "@mui/material";
import { Experimental_CssVarsProvider as CssVarsProvider, experimental_extendTheme as extendTheme } from "@mui/material/styles";
import { createContext, useMemo } from "react";
import Themes, { DuskTheme } from "../shared/Themes";

export const GlobalThemeContext = createContext({
  theme: DuskTheme,
});

export const GlobalThemeProvider = ({ children }) => {
  // Can customize screensize breakpoints (and more) here:
  const globalTheme = useMemo(() => extendTheme({
    colorSchemes: {
      ...Themes
    },
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 768,
        laptop: 1024,
        desktop: 1200,
      },
    },
  }), []);

  return (
    <CssVarsProvider theme={globalTheme} defaultMode='dark' defaultColorScheme={DuskTheme}>
      <CssBaseline />
      {children}
    </CssVarsProvider>
  )
};