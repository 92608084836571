import axios from 'axios';

// const cache = {};
// create axios instance

// HN API CALLS
// Leaving this call uncached allows the app to pull in new articles
// TODO: On failure, should return cache to allow for offline operation.
export const getByCategory = (category, baseUrl, direct) => {
  // FIXME: Fix the return object to not have nested "data"
  if (!!direct) {
    return axios.get(`${baseUrl}${category}stories.json?print=pretty`);
  } else {
    return axios.post(`${baseUrl}`, {
      query: `{
        getByCategory(category: "${category}")
      }`
    }, { // headers: {   'Content-Type': 'application/json' }
    });
  }
}

export const getArticleById = (id, baseUrl, direct) => {
  if (!!direct) {
    return axios.get(`${baseUrl}item/${id}.json?print=pretty`);
  } else {
    return axios.post(`${baseUrl}`, {
      query: `{
        getArticleById(id: ${id}) {
          id
          deleted
          type
          by
          time
          text
          dead
          parent
          poll
          kids
          url
          score
          title
          parts
          descendants
        }
      }`
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}

export const getAllArticles = (articleIds, api) => {
  return axios.all(articleIds.map(id => getArticleById(id, api))).then(response => {
    console.debug('Axios All Response: ', response);
    return response.map(resp => resp.data.data.getArticleById);
  });
}

// Times I loved past me for this comment: 7
/* getArticleById model response
  // by: "ColinWright"
  // ​
  // descendants: 43
  // ​
  // id: 36374429
  // ​
  // kids: Array(19) [ 36375845, 36376161, 36375924, … ]
  // ​
  // score: 85
  // ​
  // time: 1687034894
  // ​
  // title: "GPT-4 apparently can't recite the litany against fear from Dune"
  // ​
  // type: "story"
  // ​
  // url | text: "https://twitter.com/michael_nielsen/status/1670150729422757888"
*/