import { useTheme } from '@emotion/react';
import { Box, Container, Stack } from '@mui/material';
import React, { lazy, useContext, useEffect } from 'react';
import { Button, Dropdown, Icon, Loader } from 'semantic-ui-react';
import LoadingContext from '../context/LoadingContext';
import { PaginationActions, PaginationContext, PaginationDispatchContext } from '../context/PaginationContext';
import { UIFiltersContext } from '../context/UIFiltersContext';
import { clearObjectStore } from '../service/ArticleCacheService';
import Views from '../shared/Views';
import './Root.scss';
import Controls from './controls/Controls';

const ArticleList = lazy(() => import('./views/article-list/ArticleList'));
const ArticleCard = lazy(() => import('./views/article-card/ArticleCard'));

// TODO: Extract to shared location.
// TODO: Drive access by env
const ip = '172.31.11.215';
const apiURLs = [
  {
    key: 'apollo',
    text: 'apollo',
    value: `http://${ip}:4000/`,
  },
  {
    key: 'direct',
    text: 'direct',
    value: 'https://hacker-news.firebaseio.com/v0/',
  },
  // {
  //   key: 'feign',
  //   text: 'feign',
  //   value: 'http://localhost:8082/graphql',
  // },
  {
    key: 'webclient',
    text: 'webclient',
    value: `http://${ip}:8081/graphql`,
  },
];

function Root(props) {
  const { articleIds } = { ...props };

  const { loading } = useContext(LoadingContext);
  const { loadedArticleIds, pageSize } = useContext(PaginationContext);
  const dispatch = useContext(PaginationDispatchContext);
  const { api, category, view } = useContext(UIFiltersContext);

  const theme = useTheme();
  const isProd = process.env.NODE_ENV === 'production';

  useEffect(() => {
    dispatch({ type: PaginationActions.INIT, newState: { articleIds } });
  }, [articleIds, dispatch]);

  // needs to behave differently Card vs List?
  useEffect(() => {
    const handleScroll = () => {
      dispatch({ type: PaginationActions.UPDATE });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      dispatch({ type: PaginationActions.RESIZE });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [dispatch]);

  const handleAPIChange = (e, newAPI) => {
    dispatch({ api: newAPI.value })
  };

  return (
    <Stack spacing={5} sx={{ height: '100%', backgroundColor: theme.primary || 'purple' }}>
      <Controls />
      {!loading && false && <div className='debug-panel'>
        <h2>Debug v0.0.3:</h2>
        <span className='debug-controls'>
          <span>
            <Dropdown
              button
              defaultValue={api ?? apiURLs[1].value}
              disabled={isProd}
              fluid
              inline
              icon={<Icon></Icon>}
              options={apiURLs}
              onChange={handleAPIChange}
              placeholder='Select API'
            />
          </span>
          <Button name="clear-db" onClick={clearObjectStore}>Clear DB</Button>
        </span>
        <span className='debug-controls'>
          <span>'View:' {view}</span>
          <span>'Category:' {category}</span>
          <span> Articles: {articleIds?.length ?? 'none'} </span>
          <span> Loaded: {loadedArticleIds?.length ?? 'none'} </span>
          <span> Page Size: {pageSize} </span>
        </span>
      </div>}
      <Box alignItems={'center'} display={'flex'} justifyContent={'center'} sx={{ backgroundColor: theme.primary || 'yellow' }}>
        {!articleIds.length ? <Loader active inverted /> : <>
          {<Container sx={{ maxWidth: 'lg', height: '100%' }}>
            <main className='card-main'>
              {view === Views.CARD && loadedArticleIds.map(id =>
                <ArticleCard key={id} articleId={id} classes="article-card"></ArticleCard>
              )}
              {view === Views.LIST && <ArticleList articleIds={articleIds} total={articleIds.length}></ArticleList>}
            </main>
          </Container>}
        </>}
      </Box>
    </Stack >
  );
}

// Root.whyDidYouRender = process.env.REACT_APP_WDYR;
export default React.memo(Root);