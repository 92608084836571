import axios from 'axios';
import { getArticleById } from "./ArticleService";

// TODO: Use db in article context instead?
const DB_NAME = 'hacker-news';
const DB_VERSION = 1; // Use a long long for this value (don't use a float)
const DB_STORE_NAME = 'articles';

// FIXME: This feels fragile/susceptible to a race condition.
// There must be a better place to init the DB and ensure it was successful
openDb();
var hackerNewsIndexedDB;

export function openDb() {
  console.log("openDb ...");
  var req = indexedDB.open(DB_NAME, DB_VERSION); // the `indexedDB referenced here is a globally provided object.
  req.onsuccess = function (evt) {
    hackerNewsIndexedDB = this.result;
    console.log("hackerNewsIndexedDB:articles READY");
  };
  req.onerror = function (evt) {
    console.error("openDb:error", evt.target.errorCode);
  };

  // This listener is apparently not necessary anymore
  // [Adding Data](https://dev.to/esponges/indexeddb-your-offline-and-serverless-db-in-your-browser-with-react-3hm7#AddingData)
  req.onupgradeneeded = function (evt) {
    console.log("openDb.onupgradeneeded");
    var store = evt.currentTarget.result.createObjectStore(
      DB_STORE_NAME, { keyPath: 'id' });

    // DB Schema. TODO: Should be a away to simplify/extract
    store.createIndex('id', 'id', { unique: true });
    store.createIndex('deleted', 'deleted', { unique: false });
    store.createIndex('type', 'type', { unique: false });
    store.createIndex('by', 'by', { unique: false });
    store.createIndex('time', 'time', { unique: false });
    store.createIndex('text', 'text', { unique: false });
    store.createIndex('dead', 'dead', { unique: false });
    store.createIndex('parent', 'parent', { unique: false });
    store.createIndex('poll', 'poll', { unique: false });
    store.createIndex('kids', 'kids', { unique: false });
    store.createIndex('url', 'url', { unique: false });
    store.createIndex('score', 'score', { unique: false });
    store.createIndex('title', 'title', { unique: false });
    store.createIndex('part', 'part', { unique: false });
    store.createIndex('descendants', 'descendants', { unique: false });
    // Custom columns
    store.createIndex('bookmarked', 'bookmarked', { unique: false });
  };
};

/**
 * @param {string} store_name
 * @param {string} mode either "readonly" or "readwrite"
 */
function getObjectStore(store_name, mode) {
  var tx = hackerNewsIndexedDB.transaction(store_name, mode);
  return tx.objectStore(store_name);
}

export function clearObjectStore() {
  var store = getObjectStore(DB_STORE_NAME, 'readwrite');
  var req = store.clear();
  req.onsuccess = function (evt) {
    console.log('clearObjectStore:success: ', evt);
  };
  req.onerror = function (evt) {
    console.error("clearObjectStore:error: ", evt.target.errorCode);
  };
}

// CRUD Actions TODO: Can CRUD operations and DB connection (everything above) be separated?
export function addArticle(article) {
  if (!article) return; // FIXME: should not be adding falsy articles
  return new Promise(resolve => {
    const transaction = hackerNewsIndexedDB.transaction([DB_STORE_NAME], 'readwrite');
    const objectStore = transaction.objectStore(DB_STORE_NAME);
    const request = objectStore.add(article);

    request.onsuccess = (event) => {
      resolve(article);
    };

    /**
     * Times triggered: 1
     * Common errors:
     */
    request.onerror = (event) => {
      if (request.error.name === 'ConstraintError') {
        console.error('Article violates DB constraints: ', article); // handle the error
        event.preventDefault(); // don't abort the transaction
      } else {
        console.error('UNEXPECTED ERROR');
        // unexpected error, can't handle it
        // the transaction will abort
      }
    };
  });
}

export function updateCacheById(articleId, api) {
  const transaction = hackerNewsIndexedDB.transaction([DB_STORE_NAME], 'readwrite');
  const objectStore = transaction.objectStore(DB_STORE_NAME);
  const request = objectStore.get(articleId);

  /**
 * Times triggered: 0
 * Common errors:
 */
  request.onerror = (event) => {
    console.error('UNEXPECTED ERROR:updateCacheById: ', event);
  };

  request.onsuccess = (event) => {
    const article = event.target.result;

    article.bookmarked = true;

    const articleUpdate = objectStore.put(article);

    articleUpdate.onerror = (event) => {
      console.error('UNEXPECTED ERROR:updateCacheById:articleUpdate: ', event);
    };

    articleUpdate.onsuccess = (event) => {
      console.debug('articleUpdate:onsuccess: ', event);
    }
  };
}

export function getBookmarked() {
  // const transaction = hackerNewsIndexedDB.transaction([DB_STORE_NAME], 'read');
  // const objectStore = transaction.objectStore(DB_STORE_NAME);
  // const request = objectStore.getAll()
  debugger;
}

// TODO: Extract to shared location.
// TODO: Drive access by env
const ip = '172.31.11.215';
const apiURLs = [
  {
    key: 'apollo',
    text: 'apollo',
    value: `http://${ip}:4000/`,
  },
  {
    key: 'direct',
    text: 'direct',
    value: 'https://hacker-news.firebaseio.com/v0/',
  },
  // {
  //   key: 'feign',
  //   text: 'feign',
  //   value: 'http://localhost:8082/graphql',
  // },
  {
    key: 'webclient',
    text: 'webclient',
    value: `http://${ip}:8081/graphql`,
  },
];

export function getCachedById(articleId, api) {
  // console.debug('ArticleCacheService:getCachedById::', articleId);
  return new Promise(resolve => {
    const transaction = hackerNewsIndexedDB.transaction([DB_STORE_NAME], 'readwrite');
    const objectStore = transaction.objectStore(DB_STORE_NAME);
    const request = objectStore.get(+articleId);
    var direct = apiURLs[1].value === api;

    request.onsuccess = (event) => {
      if (!!request?.result) {
        resolve(request.result);
      } else {
        getArticleById(articleId, api, direct).then(queryResult => {
          if (!!queryResult.data) {
            addArticle(queryResult?.data).then(article => {
              // addArticle(!!direct ? queryResult.data : queryResult.data.data.getArticleById).then(article => {
              resolve(article);
            });
          } else {
            console.log('Bad article: ', articleId, queryResult);
            addArticle({ id: articleId, dead: true, url: `https://news.ycombinator.com/item?id=${articleId}` }).then(article => {
              resolve(article);
            });
          }
        }).catch(e => {
          console.error('ArticleCacheService:getCachedById:getArticleById:error: ', e);
        });
      }
    };

    /**
     * Times triggered: 0
     * Common errors:
     * TODO: Can/should this be `request.onerror` instead? Is this ever even hit?
     */
    transaction.onerror = (event) => {
      if (request.error.name === 'ConstraintError') {
        console.error('Article violates DB constraints: ', articleId); // handle the error
        event.preventDefault(); // don't abort the transaction
      } else {
        console.error('UNEXPECTED ERROR');
        // unexpected error, can't handle it
        // the transaction will abort
      }
    };
  });
}

export function getCachedByIds(articleIds, api) {
  // Could probably use `Promise.all` instead of `axios.all`. Would it matter?
  return axios.all(articleIds.map(articleId => {
    return new Promise(resolve => {
      getCachedById(articleId, api).then(result => {
        resolve(result);
      });
    });
  }));
}