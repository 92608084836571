const variables = {
  slategray: '#708090', // rgb(112,128,144)
  whitish: '#f5f5f5', // rgb(224,225,226)
  lightaccent: '#7E8C9A', // rgb(126, 140, 154)

  deepocean: '#282a36', // rgb(40,42,54)
  darkgray: '#2c2c2c', // rgb(44,44,44)
  darkaccent: '#1A1E2C', // rgb(26, 30, 44, )
}

export const WindowsClassic = {
  backgroundColor: variables.slategray,
  palette: {
    mode: 'light',
  },
  accent: variables.lightaccent,
  primary: variables.slategray,
  secondary: variables.whitish,
  inverse: variables.slategray,
  inverseaccent: variables.slategray,
  // badge: ,
}

export const DuskTheme = {
  backgroundColor: variables.deepocean,
  palette: {
    mode: 'dark'
  },
  accent: variables.darkaccent,
  primary: variables.deepocean,
  secondary: variables.darkgray,
  inverse: variables.whitish,
  inverseaccent: variables.lightaccent,
}

const Themes = {
  dark: DuskTheme,
  // dusk: DuskTheme,
  light: WindowsClassic,
}

export default Themes;