import { createContext, useReducer } from 'react';

export const PaginationContext = createContext(null);
export const PaginationDispatchContext = createContext(null);
export const PaginationActions = {
  INIT: 'init', // setarticles..
  RESIZE: 'resize',
  UPDATE: 'update', // scroll?
};

function paginationReducer(state, action) {
  const { type, newState = {} } = action;
  const pendingState = { ...state, ...newState };

  switch (type) {
    case PaginationActions.INIT:
      pendingState.loadedArticleIds = newState.articleIds.slice(0, state.pageSize + 1);
      break;
    case PaginationActions.RESIZE:
      pendingState.pageSize = Math.floor(window.innerWidth / 240) * Math.floor(window.innerHeight / 300);
      break;
    case PaginationActions.UPDATE:
      // .SCROLL // suuuuuper hacky. specific to CARD view 
      // ? If the view isn't at the very top
      if ((window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight)) {
        // Last page ? -> Math.min(page.current + 1, Math.floor(articleIds.length / pageSize));
        // Some of this logic should probably reliable on something constant, rather than state updates
        // pendingState.page = pendingState.articleIds.length
        if ((pendingState.pageSize * pendingState.page) < pendingState.articleIds.length) {
          // if (pendingState.articleIds.length / pendingState.pageSize / pendingState.page >= 1) {
          pendingState.page++;
        }
      }
      // Determines whether or not to go to the "next page". Card view specific. Problematic.
      const nextPage = pendingState.articleIds.slice(0, pendingState.pageSize * pendingState.page);
      // console.debug('paginationReducer:nextPage:: ', nextPage);
      if (nextPage.some(id => !pendingState.loadedArticleIds.includes(id))) {
        pendingState.loadedArticleIds = pendingState.articleIds.slice(0, pendingState.pageSize * pendingState.page);
      }
      break;
    default:
      return state;
  }

  return pendingState;
}

const initialPagination = {
  articleIds: [],
  loadedArticleIds: [],
  page: 1,
  pageSize: Math.floor(window.innerWidth / 240) * Math.floor(window.innerHeight / 300) + 1, // extract
}

export const PaginationProvider = ({ children }) => {
  const [pagination, dispatch] = useReducer(
    paginationReducer,
    initialPagination,
  );

  return (
    <PaginationContext.Provider value={pagination}>
      <PaginationDispatchContext.Provider value={dispatch}>
        {children}
      </PaginationDispatchContext.Provider>
    </PaginationContext.Provider>
  );
}