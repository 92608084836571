import { createContext, useReducer } from "react";
import Views from "../shared/Views";

export const UIFiltersContext = createContext(null);
export const UIFiltersDispatchContext = createContext(null);

// Overly simple for a reducer? Combine with articleIds?
function uiFiltersReducer(filters, value) {
  if (filters[Object.keys(value)[0]] === Object.values(value)[0])
    return filters;

  const newFilters = { ...filters, ...value };
  localStorage.setItem('filters', JSON.stringify(newFilters));

  return newFilters;
}

const ip = 'https://hacker-news.firebaseio.com/v0/';
const initialUIFilters = {
  api: `${ip}`,
  category: 'top', // extract me, too
  view: Views.CARD,
  ...JSON.parse(localStorage.getItem('filters')),
};

export const UIFiltersProvider = ({ children }) => {
  const [uiFilters, dispatch] = useReducer(
    uiFiltersReducer,
    initialUIFilters,
  );

  return (
    <UIFiltersContext.Provider value={uiFilters}>
      <UIFiltersDispatchContext.Provider value={dispatch}>
        {children}
      </UIFiltersDispatchContext.Provider>
    </UIFiltersContext.Provider>
  );
}

