import { useTheme } from '@emotion/react';
import { DarkMode, FilterAlt, GridView, LightMode, ViewList } from '@mui/icons-material';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Menu, MenuItem, MenuList, ToggleButton, ToggleButtonGroup, Tooltip, useColorScheme, useMediaQuery } from '@mui/material';
import Fab from '@mui/material/Fab';
import React, { useContext, useEffect, useState } from 'react';
import ArticlesContext from '../../context/ArticlesContext';
import LoadingContext from '../../context/LoadingContext';
import { UIFiltersContext, UIFiltersDispatchContext } from '../../context/UIFiltersContext';
import { getByCategory } from '../../service/ArticleService';
import Views from '../../shared/Views';
import './Controls.scss';

// Seems unnecessary; make work with enum
// const articleCategories = [ 'best', 'new', 'top' ];
const articleCategories = [
  {
    key: 'ask',
    text: 'Ask',
    value: 'ask',
  },
  {
    key: 'best',
    text: 'Best',
    value: 'best',
  },
  {
    key: 'job',
    text: 'Job',
    value: 'job',
  },
  {
    key: 'new',
    text: 'New',
    value: 'new',
  },
  {
    key: 'show',
    text: 'Show',
    value: 'show',
  },
  {
    key: 'top',
    text: 'Top',
    value: 'top',
  },
];

const themes = [
  {
    className: 'light',
    key: 'light',
    text: 'Windows',
    value: 'light',
  },
  {
    className: 'dark',
    key: 'dark',
    text: 'Dark',
    value: 'dark',
  },
  // {
  //   key: 'dusk',
  //   text: 'Dusk',
  //   value: 'dusk',
  // },
  // {
  //   key: 'windows',
  //   text: 'Windows',
  //   value: 'windows',
  // },
];

// Component
const Controls = () => {
  const muiTheme = useTheme();
  const { mode, setMode } = useColorScheme();
  const largeScreenMediaQuery = useMediaQuery(muiTheme.breakpoints.up('tablet')); // Variable for media query 
  const setArticleIds = useContext(ArticlesContext);
  const setLoading = useContext(LoadingContext);
  const { api, category, view } = useContext(UIFiltersContext);
  const dispatch = useContext(UIFiltersDispatchContext);

  const [showReturnFAB, setShowReturnFAB] = useState(false);
  // Category menu. TODO: Try non-anchor approach
  const [anchorEL, setAnchorEl] = useState(null);
  const open = Boolean(anchorEL);

  const selectedTheme = localStorage.getItem('selectedTheme') || themes[0].value;
  useEffect(() => {
    setLoading(true);
    // FIXME: This is a very fragile way to drive this.
    var direct = 'https://hacker-news.firebaseio.com/v0/' === api;
    getByCategory(category || 'top', api, direct).then(result => {
      // TODO: destructure this better
      setArticleIds(direct ? result.data : result.data.data.getByCategory, api);
    }).catch(e => {
      console.error(e);
    }).finally(() => {
      setLoading(false);
    });
  }, [api, category, setArticleIds, setLoading]);

  // TODO: Extract ScrollToTop component.
  useEffect(() => {
    const scrollHandler = (e) => {
      setShowReturnFAB(!!window.scrollY);
    };
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    }
  }, []);

  const handleThemeChange = (e, newTheme) => {
    console.debug('Theme change: ', mode, newTheme, e);
    // TODO: This should be removed after a fully fledged theme picker is utilized
    localStorage.setItem('selectedTheme', newTheme);
    setMode(newTheme);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryChange = (event) => {
    console.debug('Category change: ', event, event.target.innerText);
    handleClose();
    dispatch({ category: event.target.innerText.toLowerCase() });
  };

  const handleViewChange = (e, newView) => {
    if (!!newView) {
      console.debug('View change: ', newView);
      dispatch({ view: newView });
    }
  };

  return (
    <Box className='controls-container'>
      {/* This approach doesn't align with MUIThemes. Is needed for SemanticUI fix. */}
      <span className={'controls-buttons ' + (muiTheme.palette.mode)}>
        <span>
          <ToggleButtonGroup className='theme-picker'
            exclusive
            selected={selectedTheme}
            onChange={handleThemeChange}
            size='small'
            value={selectedTheme}
          >
            <ToggleButton aria-label='light mode' disabled={selectedTheme === 'light'} fullWidth={selectedTheme !== 'light'} value='light'>
              <LightMode />
            </ToggleButton>
            <ToggleButton aria-label='dark mode' disabled={selectedTheme === 'dark'} fullWidth={selectedTheme !== 'dark'} value='dark'>
              <DarkMode />
            </ToggleButton>
          </ToggleButtonGroup>
        </span>
        <span className='view-buttons'>
          <ToggleButtonGroup className={'theme-picker item ' + muiTheme.palette.mode}
            exclusive onChange={handleViewChange} value={view}>
            <ToggleButton
              aria-label='card view'
              disabled={view === Views.CARD}
              fullWidth={view !== Views.CARD}
              size='small'
              value={Views.CARD}>
              <GridView />
            </ToggleButton>
            <ToggleButton
              aria-label='list view'
              disabled={view === Views.LIST}
              fullWidth={view !== Views.LIST}
              size='small'
              value={Views.LIST}>
              <ViewList />
            </ToggleButton>
          </ToggleButtonGroup>
        </span>
      </span>
      {<span className='fab-container top'>
        <Tooltip title='Change filter'>
          <Fab
            aria-label='change filter'
            onClick={handleClick}
            sx={{ backgroundColor: muiTheme.accent }}
            size={largeScreenMediaQuery ? 'medium' : 'large'}
          >
            <FilterAlt />
          </Fab>
        </Tooltip>
        <Menu anchorEl={anchorEL}
          onClose={handleClose}
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuList sx={{ display: 'flex', padding: 0 }}>
            {articleCategories.map(cat =>
              <MenuItem
                key={cat.key}
                onClick={handleCategoryChange}
                selected={category === cat.value}
                value={'' + cat.value}
              >
                {cat.text}
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </span>}
      {!!showReturnFAB && <span className='fab-container bottom'>
        <Tooltip className='tooltip' title='Scroll to top'>
          <Fab
            aria-label='scroll up'
            className='fab-button'
            sx={{ backgroundColor: muiTheme.accent }}
            href={'#top'}
            size={largeScreenMediaQuery ? 'medium' : 'large'}
          >
            <UpIcon />
          </Fab>
        </Tooltip>
      </span>}
    </Box>
  )
}

// Controls.whyDidYouRender = process.env.REACT_APP_WDYR;
export default React.memo(Controls);