import { useTheme } from '@emotion/react';
import { Container } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { Suspense, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import './App.scss';
import Root from './components/Root';
import ArticlesContext from './context/ArticlesContext';
import LoadingContext from './context/LoadingContext';
import { PaginationProvider } from './context/PaginationContext';
import { GlobalThemeProvider } from './context/ThemeContext';
import { UIFiltersProvider } from './context/UIFiltersContext';

function App() {
  const [articleIds, setArticleIds] = useState([]);
  const [loading, setLoading] = useState(true);
  // FIXME: Could lifting the colorScheme setter up help with flash? // const { setMode } = useColorScheme();
  // Maybe lift the ContextProvider somehow.
  const theme = useTheme();

  // FIXME: This is big duuuuumb.
  // Used by ScrollToTop FAB
  window.addEventListener('hashchange', function (e) {
    // eslint-disable-next-line no-restricted-globals
    history.replaceState('', document.title, e.oldURL);
  });

  return (
    <ArticlesContext.Provider value={setArticleIds}>
      <LoadingContext.Provider value={setLoading}>
        <UIFiltersProvider>
          <PaginationProvider>
            <CssVarsProvider>
              <GlobalThemeProvider>
                <Suspense fallback={<Loader active />}>
                  <Container className="App" sx={{ height: '100%', backgroundColor: theme.backgroundColor || 'red' }} disableGutters>
                    <Root articleIds={articleIds} />
                    {<Dimmer active={loading}><Loader inverted /></Dimmer>}
                  </Container>
                </Suspense>
              </GlobalThemeProvider>
            </CssVarsProvider>
          </PaginationProvider>
        </UIFiltersProvider>
      </LoadingContext.Provider>
    </ArticlesContext.Provider >
  );
}

// App.whyDidYouRender = process.env.REACT_APP_WDYR;
export default App;